:root {
  --primary-color: #4A90E2;
  --secondary-color: #50E3C2;
  --background-color: #F5F7FA;
  --text-color: #333333;
  --accent-color: #FF6B6B;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #3A80D2;
}

.input-field {
  border: 1px solid #D1D5DB;
  border-radius: 0.25rem;
  padding: 0.5rem;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}